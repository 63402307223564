<template>
  <b-modal
    id="modal-sign-invoice"
    size="lg"
    centered
    header-bg-variant="light-primary"
    no-close-on-backdrop
    body-class="p-1 modal-sign-invoice"
    title-class="h2 font-weight-bolder text-dark"
    :title="$t('invoice.signModal.title')"
    hide-footer
    hide-header-close
    @show="onShowHandle"
    @hide="onHideHandle"
  >
    <div>
      <b-form-group label-for="signType">
        <template #label>
          {{ $t('invoice.signModal.signType') }}
          <span class="text-danger">(*)</span>
        </template>
        <v-select
          id="signType"
          v-model="signType"
          :name="$t('invoice.signModal.signType')"
          :options="SIGN_TYPE_OPTIONS"
          label="label"
          :clearable="false"
          :reduce="(val) => val.value"
          :placeholder="$t('invoice.signModal.phSignType')"
        >
          <template #option="data">
            {{ $t(`invoice.signModal.${data.label}`) }}
          </template>
          <template #selected-option="data">
            {{ $t(`invoice.signModal.${data.label}`) }}
          </template>
          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>

      <!-- ANCHOR: SIGN_TYPE.FILE -->
      <div v-if="!isToken">
        <b-table
          ref="refTable"
          :items="certificatesList"
          :fields="tableColumns"
          responsive
          small
          hover
          no-border-collapse
          sticky-header
          show-empty
          :empty-text="$t('noMatchingResult')"
          :busy="isLoading"
          select-mode="single"
          selectable
          @row-clicked="onRowSelected"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]
          >
            <div
              :key="column.key"
              class="text-dark d-flex-center"
            >
              {{ $t(`invoice.signModal.columns.${column.key}`) }}
            </div>
          </template>
          <template #table-busy>
            <div class="d-flex-center text-dark my-2 gap-2">
              <b-spinner class="align-middle" />
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #cell(checkbox)="row">
            <b-form-checkbox
              :checked="row.item.serial === certificateSelected"
              @change="onRowSelected(row.item)"
            />
          </template>

          <template #cell(organizationName)="row">
            {{ row.item.organizationName }}
          </template>

          <template #cell(serial)="row">
            <code>
              {{ row.item.serial }}
            </code>
          </template>

          <template #cell(startDate)="row">
            {{ convertISODateTime(row.item.startDate).date }}
          </template>

          <template #cell(endDate)="row">
            {{ convertISODateTime(row.item.endDate).date }}
          </template>
        </b-table>
      </div>
      <!-- ANCHOR: SIGN_TYPE.USB_TOKEN -->
      <div v-else>
        <b-alert
          v-if="!isPluggingInUSB"
          show
          variant="warning"
          class="px-2 py-1"
        >
          Vui lòng kết nối USB Token và bật phần mềm đọc token!
        </b-alert>
        <div v-else>
          <b-table
            ref="refTable"
            :items="certificatesList"
            :fields="tableColumns"
            responsive
            small
            hover
            no-border-collapse
            sticky-header
            show-empty
            :empty-text="$t('noMatchingResult')"
            :busy="isLoading"
            select-mode="single"
            selectable
            @row-clicked="onRowSelected"
          >
            <template
              v-for="column in tableColumns"
              #[`head(${column.key})`]
            >
              <div
                :key="column.key"
                class="text-dark d-flex-center"
              >
                {{ $t(`invoice.signModal.columns.${column.key}`) }}
              </div>
            </template>
            <template #table-busy>
              <div class="d-flex-center text-dark my-2 gap-2">
                <b-spinner class="align-middle" />
                <strong>{{ $t('loading') }}</strong>
              </div>
            </template>

            <template #cell(checkbox)="row">
              <b-form-checkbox
                :checked="row.item.serial === certificateSelected"
                @change="onRowSelected(row.item)"
              />
            </template>

            <template #cell(organizationName)="row">
              {{ row.item.Subject }}
            </template>

            <template #cell(serial)="row">
              <code>
                {{ row.item.serial }}
              </code>
            </template>

            <template #cell(startDate)="row">
              {{ convertISODateTime(row.item.startDate).date }}
            </template>

            <template #cell(endDate)="row">
              {{ convertISODateTime(row.item.endDate).date }}
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- ANCHOR footer button -->
    <div class="d-flex-between mt-1">
      <BButton
        variant="secondary"
        @click="onCloseHandle"
      >
        {{ $t('close') }}
      </BButton>
      <BButton
        variant="primary"
        :disabled="!certificateSelected"
        @click="onSubmitHandle"
      >
        {{ $t('submit') }}
      </BButton>
    </div>
  </b-modal>
</template>

<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BModal,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import isNil from 'lodash/isNil'

import { SIGN_TYPE_OPTIONS, SIGN_TYPE } from '@/constants/invoice'
import { convertISODateTime } from '@/@core/utils/filter'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'
import { apiInvoice } from '@/api'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BTable,
    BSpinner,
    BAlert,
    BFormCheckbox,
    vSelect: () => import('vue-select'),
  },

  props: {
    companyId: {
      type: [Number, null],
      default: null,
    },
    invoiceData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { root, emit }) {
    const { companyId, invoiceData } = toRefs(props)
    const { toastError } = useToast()

    const signType = ref()
    const certificatesList = ref([])
    const certificateSelected = ref()

    const tableColumns = ref([
      { key: 'checkbox' },
      { key: 'organizationName' },
      { key: 'serial' },
      { key: 'startDate' },
      { key: 'endDate' },
    ])

    const {
      getCompanyCertificates,
      signInvoice,
      loadingGetCompanyCertificates,
    } = useInvoiceHandle()

    async function getCertificateListByFile() {
      if (isNil(companyId.value)) {
        toastError('invoice.msg.companyNotFound')
        return
      }
      certificatesList.value = await getCompanyCertificates(companyId.value)
    }

    const isToken = computed(() => signType.value === SIGN_TYPE.USB_TOKEN)
    const isPluggingInUSB = ref(false)

    const intervalId = ref()
    const xmlInvoice = ref()

    // ANCHOR: USB_TOKEN handle
    async function handleUsbToken() {
      const invoiceId = invoiceData.value?.id
      if (!invoiceId) {
        toastError('lỗi invoiceId')
        return
      }
      root.$bvModal.show('modal-api-loading')
      try {
        xmlInvoice.value = await apiInvoice.getXmlInvoice(invoiceId)
      } finally {
        root.$bvModal.hide('modal-api-loading')
      }

      async function pullingGetCertsByToken() {
        try {
          const res = await apiInvoice.getCertsByToken()
          certificatesList.value = res.data?.data ?? []
          if (res.data?.data.length) {
            isPluggingInUSB.value = true
            clearInterval(intervalId.value)
          }
        } catch (error) {
          isPluggingInUSB.value = false
        }
      }
      intervalId.value = setInterval(pullingGetCertsByToken, 1000)
    }
    watch(signType, () => {
      if (signType.value === SIGN_TYPE.FILE) {
        getCertificateListByFile()
        if (intervalId.value) {
          clearInterval(intervalId.value)
        }
      }
      else if (signType.value === SIGN_TYPE.USB_TOKEN) {
        handleUsbToken()
      }
    }, { immediate: true })

    function onCloseHandle() {
      root.$bvModal.hide('modal-sign-invoice')
    }

    async function onSubmitHandle() {
      const invoiceId = invoiceData.value?.id
      if (!invoiceId || !certificateSelected.value) return
      root.$bvModal.show('modal-api-loading')
      let res
      if (isPluggingInUSB.value) {
        if (!xmlInvoice.value || !certificateSelected.value) {
          toastError('Lỗi xmlInvoice hoặc certificateSelected')
          root.$bvModal.hide('modal-api-loading')
          return
        }
        const payload = {
          serial: certificateSelected.value,
          type: 2,
          base64XML: xmlInvoice.value,
        }
        const signedInvoiceXML = await apiInvoice.signInvoiceLocal(payload)
        res = await apiInvoice.signInvoiceByToken(invoiceId, {
          signedData: signedInvoiceXML,
        })
      } else {
        const payload = {
          serial: certificateSelected.value,
        }
        res = await signInvoice(invoiceId, payload)
      }
      if (res?.id) {
        emit('refetch')
        onCloseHandle()
      }
      root.$bvModal.hide('modal-api-loading')
    }

    function onShowHandle() {
      signType.value = SIGN_TYPE.FILE
      xmlInvoice.value = null
      intervalId.value = null
      isPluggingInUSB.value = null
      certificateSelected.value = null
      certificatesList.value = []
    }

    const refTable = ref()
    function onRowSelected(item) {
      if (certificateSelected.value === item?.serial) {
        certificateSelected.value = null
        return
      }
      certificateSelected.value = item?.serial ?? null
    }
    function onHideHandle() {
      signType.value = null
      clearInterval(intervalId.value)
    }

    const isLoading = computed(() => loadingGetCompanyCertificates.value)
    return {
      onSubmitHandle,
      onCloseHandle,
      onShowHandle,
      signType,
      SIGN_TYPE_OPTIONS,
      SIGN_TYPE,
      isToken,
      certificatesList,
      isLoading,
      tableColumns,
      convertISODateTime,
      onRowSelected,
      certificateSelected,
      refTable,
      isPluggingInUSB,
      onHideHandle,
    }
  },
}
</script>

<style lang="scss">
  .modal-sign-invoice {
    .table {
      thead th {
        vertical-align: middle;
      }

      &.b-table {
        >tbody {
          .b-table-row-selected {
            &.table-active {
              td {
                background-color: white;
                color: black;
              }
            }
          }
        }
      }
    }
  }
</style>
